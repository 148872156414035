/**
 * @typedef {string} cell
 */

/**
 * @typedef {cell[]} validateData~column
 */

/**
 * @param {column[]} rows
 * @returns {undefined}
 */
export default (rows) => {
  if (!Array.isArray(rows)) {
    throw new TypeError('Table data must be an array.');
  }

  if (rows.length === 0) {
    throw new Error('Table must define at least one row.');
  }

  if (rows[0].length === 0) {
    throw new Error('Table must define at least one column.');
  }

  const columnNumber = rows[0].length;

  for (const cells of rows) {
    if (!Array.isArray(cells)) {
      throw new TypeError('Table row data must be an array.');
    }

    if (cells.length !== columnNumber) {
      throw new Error('Table must have a consistent number of cells.');
    }

    for (const cell of cells) {
      // eslint-disable-next-line no-control-regex
      if (/[\u0001-\u0006\u0008\u0009\u000B-\u001A]/.test(cell)) {
        throw new Error('Table data must not contain control characters.');
      }
    }
  }
};
